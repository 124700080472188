<p>
  Neugierig und erstaunt von der Natur,<br/>
  Ich habe eine Leidenschaft für das Unbekannte
</p>
<p>
  Alle Nuancen des Wissensspektrums inspirieren und nähren mich, von der <a href="">Kunst</a> bis zum <a href="">Web</a>.
</p>
<p>
  Und dann schieße ich den Bogen, lese oder verbringe Zeit mit meinen Kumpels.<br/>
  Ich gehe gerne mit dem Fahrrad oder Motorrad spazieren.
</p>
