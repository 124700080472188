import { Component, OnInit } from '@angular/core';
import {ThemingService} from '../../theming.service';

@Component({
  selector: 'app-change-color-setting',
  templateUrl: './change-color-setting.component.html',
  styleUrls: ['./change-color-setting.component.scss']
})
export class ChangeColorSettingComponent {
  public themes = [
    {
      name: 'dark',
      icon: 'brightness_3'
    },
    {
      name: 'light',
      icon: 'wb_sunny'
    }
  ];

  constructor(public colorSchemeService: ThemingService) {
  }

  changeTheme(): void {
    if (this.colorSchemeService.currentActive() === 'dark' ) {
      this.colorSchemeService.update('light');
    } else {
      this.colorSchemeService.update('dark');
    }

    console.log(this.colorSchemeService.currentActive());
  }
}
