import { Component, OnInit } from '@angular/core';
import {ThemingService} from '../../theming.service';



@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  themes: string[];

  constructor() {
  }
  ComingSoon = 'coming soon';
  Email = 'pantazis.marina[a]gmail.com';
  ngOnInit(): void  {
  }


}
