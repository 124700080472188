<div class="container">
  <header>
    <div class="menu">
      <a href="#" [matTooltip]="ComingSoon">art</a>
      <img class="point" src="assets/img/point.svg">
      <a href="#" [matTooltip]="ComingSoon">web</a>
      <a href="#"><img src="assets/img/marina-pantazis-logo.svg"></a>
      <a href="#" [matTooltip]="ComingSoon">blog</a>
      <img class="point" src="assets/img/point.svg">
      <a href="#" [matTooltip]="Email">mail</a>
    </div>

  </header>

  <main class="" id="presentations">

    <img class="title-img" src="assets/img/titre-plant.svg">
    <div class="text">
      <h1>Marina Pantazis</h1>

      <router-outlet></router-outlet>

      <div style="text-align: center">
        <app-change-color-setting></app-change-color-setting>
      </div>

    </div>

    <img class="blue" src="assets/img/bg-blue.svg">
  </main>




  <!--<section class="site-programmation">-->
  <!--  <p>-->
  <!--    Je suis une Web enthousiaste par ce qu'internet est sans fin, et c'est chouette.-->
  <!--  </p>-->
  <!--  <p>-->
  <!--    *Travailler avec moi c’est quoi ?*-->
  <!--    Une écoute attentive de vos besoins.-->
  <!--    La confection de site personnalisé avec un réel impact sur votre public cible.-->
  <!--    A votre service-->
  <!--    *ce que vous financez*-->
  <!--    Une écoute attentive-->
  <!--    Un suivi en continu-->
  <!--    Un seul interlocuteur et collaborateur-->
  <!--    Un travail de qualité-->
  <!--    Un rendu personnalisé-->
  <!--  </p>-->
  <!--</section>-->

  <!--https://stock.adobe.com/fr/contributor/207202045/ngupakarti?load_type=author&prev_url=detail-->


  <footer id="footer">
    <img class="point" src="assets/img/footer-plant.svg">
    <div class="language">
      <a [routerLink]="['/FR']">FR</a>
      <span class="separator">|</span>
      <a [routerLink]="['/DE']"  [matTooltip]="ComingSoon">DE</a>
      <span class="separator">|</span>
      <a [routerLink]="['/EN']"  [matTooltip]="ComingSoon">EN</a>
    </div>
  </footer>
</div>
