import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomepageComponent } from './components/homepage/homepage.component';
import { FooterComponent } from './components/footer/footer.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { ThemingService } from './theming.service';
import { HomepageDEComponent } from './components/homepage-de/homepage-de.component';
import { HomepageEnComponent } from './components/homepage-en/homepage-en.component';
import { HomepageFRComponent } from './components/homepage-fr/homepage-fr.component';
import { MatIconModule } from '@angular/material/icon';
import { ChangeColorSettingComponent } from './components/change-color-setting/change-color-setting.component';

const routes: Routes = [
];

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    FooterComponent,
    HomepageDEComponent,
    HomepageEnComponent,
    HomepageFRComponent,
    ChangeColorSettingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    RouterModule.forRoot([
      {path: '', redirectTo: '/FR', pathMatch: 'full'},
      {path: 'DE', component: HomepageDEComponent},
      {path: 'EN', component: HomepageEnComponent,},
      {path: 'FR', component: HomepageFRComponent,}
    ]),
    MatIconModule
  ],
  providers: [ThemingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
