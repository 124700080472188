
<p>Média-magicienne, développeuse & graphiste, tout en un</p>
<p>
  Curieuse et émerveillée de nature,<br/>
  Je me passionne pour l’inconnu
</p>

<p>Toutes les nuances du spectre du savoir m'inspirent et me nourrissent. De l'
  <a href="">art</a> au <a href="">web</a>.</p>
<p>
  Et puis sinon je tire à l'arc, je lis ou je passe du temps avec mes potes.<br/>
  Je me balade volontiers à pied en vélo ou à moto.
</p>
