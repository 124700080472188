import {Component} from '@angular/core';
import {ThemingService} from './theming.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent{

  title = 'site';

  constructor(private colorSchemeService: ThemingService) {
    // Load Color Scheme
    this.colorSchemeService.load();
  }

}
